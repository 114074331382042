import { useTranslation } from '@thoughtspot/i18n';
import { jsonConstants } from '@thoughtspot/json-constants';
import { create } from '@thoughtspot/logger';
import { isPendoAllowed } from '@thoughtspot/pendo';
import { LoadingIndicator } from '@thoughtspot/radiant-react/widgets/loading-indicator';
import React, { Suspense, useEffect, useState } from 'react';
import { useLocation, useMatches } from 'react-router';
import { useAppContext } from '/@contexts/global-app-context';
import {
    isCurrentPrintViewPath,
    removeOverrideOrgId,
} from '/@services/system/nav-service/nav-service';
import {
    Application,
    ApplicationList,
    Applications,
    ROUTES,
} from '../../routing/routes';
import { UserMenuContainerWithInvite } from '../user-menu/user-menu.container';
import { ClassicNavExperience } from './classic-nav-view/classic-nav.container';
import { GlobalNav, NavLinkCallback, NavLinkConfig } from './global-nav';

const Logger = create('top-nav');

const HomeSearchBarContainer = React.lazy(() =>
    import('../home/home-search-bar/home-search-bar.container'),
);

const NavContainer: React.FC = () => {
    const { navService, sessionService } = useAppContext();
    const { t } = useTranslation();
    const matches = useMatches();
    const location = useLocation();

    const shouldShowPendoHelp =
        sessionService.isPendoHelpEnabled() &&
        isPendoAllowed(isCurrentPrintViewPath());

    const currentAppRoute = matches.find(
        match => (match.handle as any)?.handle,
    );
    const [availableApplications, setAvailableApplications] = useState<
        Application[]
    >([]);
    const [isEurekaPath, setIsEurekaPath] = useState(false);
    const [activeApplication, setActiveApplication] = useState<Application>(
        ApplicationList[Applications.INSIGHTS],
    );
    const [applicationSwitcherConfig, setApplicationSwitcherConfig] = useState<
        NavLinkConfig[]
    >([]);
    const goToApplicationHome = (applicationID: string) => {
        if (
            applicationID === 'admin' &&
            sessionService.isOrgUiEnabled() &&
            sessionService.isOrgPerUrlEnabled()
        ) {
            const currentPathBeforeHash = `${
                window.location.href.split('#')[0]
            }#`;
            let path: string =
                currentPathBeforeHash +
                (`${ApplicationList[applicationID].homePath}` || '/');
            path = removeOverrideOrgId(path);
            window.location.replace(path);
        } else if (
            navService.getCurrentPathByLocationHash(false) ===
            ApplicationList[applicationID].homePath
        ) {
            window.location.reload();
        } else {
            navService.goToUrl(`${ApplicationList[applicationID].homePath}`);
        }
    };

    useEffect(() => {
        if (currentAppRoute.id !== Applications.CORE) {
            setActiveApplication(ApplicationList[currentAppRoute.id]);
        }
    }, [ApplicationList, currentAppRoute]);

    useEffect(() => {
        const pathName = (location.pathname as string) || '';
        setIsEurekaPath(pathName.includes('/insights/eureka'));
    }, [location, activeApplication]);

    useEffect(() => {
        (async () => {
            const newList: Application[] = [];

            await Promise.all(
                Object.values(ApplicationList).map(
                    async (application, index) => {
                        if (application.id === Applications.CORE) {
                            return;
                        }

                        try {
                            await Promise.all(application.privileges);
                            newList[index] = application;
                        } catch (e) {
                            Logger.warn(e);
                            newList[index] = null;
                        }
                    },
                ),
            );

            setAvailableApplications(newList.filter(app => !!app));
        })();
    }, [ApplicationList]);

    useEffect(() => {
        const applicationConfig = availableApplications.map(
            application =>
                ({
                    name: t(application.title as any),
                    id: application.id,
                    description: t(application.description as any),
                    icon: application.icon,
                    target: {
                        callback: () => goToApplicationHome(application.id),
                        routerURI: `${
                            ApplicationList[application.id].homePath
                        }`,
                    } as NavLinkCallback,
                    pageContext: undefined,
                } as NavLinkConfig),
        );
        setApplicationSwitcherConfig(applicationConfig);
    }, [t, availableApplications]);

    const showSearchBar = sessionService.isEurekaEnabled() || sessionService.isSaaSCluster();

    // with compact mode, we'll always render the SearchBar in Global Nav
    return sessionService.isModularHomeExperienceEnabled() ?? true ? (
        <GlobalNav
            sessionService={sessionService}
            renderSearchBar={
                showSearchBar && !isEurekaPath
            }
            homeButtonConfig={{
                name: '',
                target: {
                    callback: () => goToApplicationHome(activeApplication.id),
                    routerURI: `${
                        ApplicationList[activeApplication.id].homePath
                    }`,
                } as NavLinkCallback,
            }}
            activeApplicationID={activeApplication.id}
            applicationSwitcherConfig={applicationSwitcherConfig}
            shouldShowPendoHelp={shouldShowPendoHelp}
        />
    ) : (
        <ClassicNavExperience
            searchBar={
                showSearchBar &&
                !isEurekaPath && (
                    <Suspense
                        fallback={
                            <LoadingIndicator.Global>
                                <></>
                            </LoadingIndicator.Global>
                        }
                    >
                        <HomeSearchBarContainer
                            isHomepage={false}
                            isGlobalNavEnabled={false}
                            showSageSearchBar={false}
                        />
                    </Suspense>
                )
            }
            userProfile={<UserMenuContainerWithInvite onEmailAdd={null} />}
            applicationSwitcherConfig={applicationSwitcherConfig}
            shouldShowPendoHelp={shouldShowPendoHelp}
        />
    );
};

export default NavContainer;
