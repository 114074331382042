import { initFeatureFlagsContext } from '@thoughtspot/blink-context';
import { envFlags, FLAGS } from '@thoughtspot/env-flags';
import {
    getLDContext,
    initialize as initFeatureService,
} from '@thoughtspot/feature-service';
import { create } from '@thoughtspot/logger';
import { handleLoginResponse } from '@thoughtspot/session-service';
import { setSystemConfig } from '@thoughtspot/system-config-service';
import React from 'react';
import { Subject } from 'rxjs';
import useFetch, { CachePolicies } from 'use-http';
import { initSessionService } from '/@services/system/config-service/session-service';
import { postAuthentication } from '/@services/system/lifecycle-hooks-service/lifecycle-hooks-service';
import { requestInterceptor } from '/@utils/browserOverrideForFetchAndXhr.util';
import { dispatchNonMemoEvent } from '/@utils/event-bridge/event-bridge';
import { EventType } from '../../utils/embed.util';
import { useMutation } from '../../utils/error-handling/apollo-hooks-wrappers/custom-apollo-hooks';
import {
    DoLogoutDocument,
    DoLogoutMutation,
    DoLogoutMutationVariables,
} from '../generated/graphql-types';
import { initCustomStylingService } from './config-service/custom-styling-service';

const Logger = create('login-service');

let isAuthenticatedSubject: Subject<boolean> = new Subject<boolean>();

const useSingletonAuthenticationSubject = () => {
    if (isAuthenticatedSubject.isStopped || isAuthenticatedSubject.hasError) {
        isAuthenticatedSubject = new Subject<boolean>();
    }
};

const updateServices = async ({
    info,
    config,
    style,
    launchdarklyFlags,
}: any) => {
    if (config) {
        setSystemConfig(config);
    }
    if (style) {
        initCustomStylingService(style);
    }
    if (info) {
        await postAuthentication(info);
    }
    if (launchdarklyFlags) {
        initFeatureFlagsContext({ launchdarklyFlags });
        initFeatureService(getLDContext(), launchdarklyFlags);
    }
};

export const getIsUserAuthenticated = (): Promise<boolean> => {
    if (isAuthenticatedSubject.hasError) {
        return Promise.resolve(false);
    }
    if (isAuthenticatedSubject.isStopped) {
        return Promise.resolve(true);
    }
    return new Promise<boolean>(resolve => {
        return isAuthenticatedSubject.subscribe((isAuthenticated: boolean) => {
            return resolve(isAuthenticated);
        });
    });
};

export const useAuthenticate = async () => {
    useSingletonAuthenticationSubject();
    let fetchResponse;

    if ((window as any)?.preauthInfo) {
        fetchResponse = (window as any)?.preauthInfo;

        const data = await fetchResponse.clone().json();

        if (data) {
            updateServices(data);
        }

        if (data.status === 200) {
            isAuthenticatedSubject.next(true);
            isAuthenticatedSubject.complete();
        } else {
            dispatchNonMemoEvent(EventType.AuthFailure, null);
            isAuthenticatedSubject.next(false);
            isAuthenticatedSubject.error(null);
        }
    } else {
        const fetchResponse = useFetch(
            '/prism/preauth/info',
            {
                method: 'GET',
                headers: {
                    'x-requested-by': 'ThoughtSpot',
                },
                interceptors: {
                    request: requestInterceptor,
                },
            },
            [],
        );
        const { data, response } = fetchResponse;
        React.useEffect(() => {
            if (data) {
                updateServices(data);
            }
            if (response.ok) {
                isAuthenticatedSubject.next(true);
                isAuthenticatedSubject.complete();
            } else if (response.ok === false) {
                dispatchNonMemoEvent(EventType.AuthFailure, null);
                isAuthenticatedSubject.next(false);
                isAuthenticatedSubject.error(null);
            }
        }, [data, response.ok]);
    }

    return fetchResponse;
};

/**
 * Demo login for a public user. Doesn't require a username or password.
 */
export const useDemoLogin = () => {
    const { data, post, error, loading, response } = useFetch(
        '/prism/preauth/demo/login',
        {
            method: 'POST',
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
                'x-requested-by': 'ThoughtSpot',
            },
            cachePolicy: CachePolicies.NO_CACHE,
        },
    );
    React.useEffect(() => {
        if (response.ok) {
            updateServices(data);
            isAuthenticatedSubject.next(true);
            isAuthenticatedSubject.complete();
        } else if (response.ok === false) {
            dispatchNonMemoEvent(EventType.AuthFailure, null);
            isAuthenticatedSubject.next(false);
            isAuthenticatedSubject.error(null);
        }
    }, [data, response]);
    return {
        error,
        loading,
        login: () => {
            useSingletonAuthenticationSubject();
            (window as any).preauthInfo = post();
            return (window as any).preauthInfo;
        },
        response,
    };
};

export const useLogin = () => {
    const { data, post, error, loading, response } = useFetch(
        '/prism/preauth/login',
        {
            method: 'POST',
            headers: {
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
                'x-requested-by': 'ThoughtSpot',
            },
            cachePolicy: CachePolicies.NO_CACHE,
        },
    );
    function login(username: string, password: string, rememberMe: boolean) {
        const encodedUsername = encodeURIComponent(username);
        const encodePassword = encodeURIComponent(password);
        useSingletonAuthenticationSubject();
        (window as any).preauthInfo = post('', {
            username: encodedUsername,
            password: encodePassword,
            rememberMe,
        });
        return (window as any).preauthInfo;
    }
    React.useEffect(() => {
        if (response.ok) {
            updateServices(data);
            isAuthenticatedSubject.next(true);
            isAuthenticatedSubject.complete();
        } else if (response.ok === false) {
            dispatchNonMemoEvent(EventType.AuthFailure, null);
            isAuthenticatedSubject.next(false);
            isAuthenticatedSubject.error(null);
        }
    }, [data, response]);

    return {
        error,
        loading,
        login,
        response,
    };
};

export const useUpdateSessionInfo = async (onlyUpdateSessionInfo = false) => {
    try {
        useSingletonAuthenticationSubject();

        let data;
        if ((window as any)?.preauthInfo) {
            data = await (window as any)?.preauthInfo;
        } else {
            (window as any).preauthInfo = fetch('/prism/preauth/info', {
                method: 'GET',
                headers: {
                    'x-requested-by': 'ThoughtSpot',
                },
            });
            data = await (window as any)?.preauthInfo;
        }

        const authInfo = await data.clone().json();
        if (onlyUpdateSessionInfo) {
            initSessionService(authInfo?.info);
            return;
        }
        await updateServices(authInfo);
        if (data.status === 200) {
            isAuthenticatedSubject.next(true);
            isAuthenticatedSubject.complete();
        } else {
            throw new Error(data.status.toString());
        }
    } catch (e) {
        dispatchNonMemoEvent(EventType.AuthFailure, null);
        isAuthenticatedSubject.next(false);
        isAuthenticatedSubject.error(null);
        Logger.warn('Error fetching session data', e);
    }
};

export const useDoLogout = () => {
    useSingletonAuthenticationSubject();
    const [_doLogout, { data, error, loading }] = useMutation<
        DoLogoutMutation,
        DoLogoutMutationVariables
    >(DoLogoutDocument);

    return {
        data: data?.Session__doLogout,
        error,
        loading,
        doLogout: () => {
            return _doLogout({}).then((): any => {
                isAuthenticatedSubject.next(false);
                isAuthenticatedSubject.error(null);
                return null;
            });
        },
    };
};

export const doShortLivedLogin = () => {
    const { post } = useFetch('callosum/v1/session/shortlivedlogin', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'x-requested-by': 'ThoughtSpot',
        },
        cachePolicy: CachePolicies.NO_CACHE,
    });
    function shortLivedLogin(userId: string, authToken: string): Promise<any> {
        const formData = new URLSearchParams();
        formData.append('id', userId);
        formData.append('shortlivedauthenticationtoken', authToken);
        return post(formData).then(handleLoginResponse);
    }

    return { shortLivedLogin };
};

export const useAuthentication = () => {
    const { doLogout } = useDoLogout();
    const { shortLivedLogin } = doShortLivedLogin();

    const authenticate = async (userGuid: string, authToken: string) => {
        try {
            await doLogout();
        } catch (error) {
            // Do nothing if already logged out
        }

        try {
            await shortLivedLogin(userGuid, authToken);
            return { authenticated: true };
        } catch (error) {
            return { authenticated: false, error };
        }
    };

    return { authenticate };
};
