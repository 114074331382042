import { Microfrontend } from '@thoughtspot/microfrontends';

export const MICRO_FRONTEND_DYNAMIC_IMPORT: { [key in Microfrontend]: any } = {
    [Microfrontend.EUREKA]: () => import('@thoughtspot/eureka'),
    [Microfrontend.EMBED_DEV_PORTAL]: () => import('@thoughtspot/embed'),
    [Microfrontend.HOME]: () => import('@thoughtspot/home'),
    [Microfrontend.DATA_MODEL]: () => import('@thoughtspot/datamodel'),
    [Microfrontend.ADMIN]: () => import('@thoughtspot/admin'),
    [Microfrontend.SEEKWELL]: () => import('@thoughtspot/seekwell'),
    [Microfrontend.SEER]: () => import('@thoughtspot/seer'),
    [Microfrontend.CONV_ASSIST]: () => import('@thoughtspot/conv-assist'),
    [Microfrontend.EDGE]: () => import('@thoughtspot/edge'),
    [Microfrontend.COPILOT]: () => import('@thoughtspot/copilot'),
    [Microfrontend.QUERY_VISUALIZER]: () =>
        import('@thoughtspot/query-visualizer'),
    [Microfrontend.TEAMS]: () => import('@thoughtspot/teams'),
    [Microfrontend.COMS]: () => import('@thoughtspot/coms'),
    [Microfrontend.MONITOR]: () => import('@thoughtspot/monitor'),
    [Microfrontend.GLOBAL_NAV]: () => import('@thoughtspot/global-nav'),
    [Microfrontend.WORKSHEET_V2]: () => import('@thoughtspot/worksheet-v2'),
    [Microfrontend.EMBRACE]: () => import('@thoughtspot/embrace'),
    [Microfrontend.CAPTAIN]: () => import('@thoughtspot/captain'),
    [Microfrontend.ONBOARDING]: () => import('@thoughtspot/onboarding'),
    [Microfrontend.SAGE_COACH]: () => import('@thoughtspot/sage-coach'),
    [Microfrontend.HOSTED_CHARTS]: () => import('@thoughtspot/hosted-charts'),
};
